.progressbar {
  margin-bottom: 30px;
  overflow: hidden;
  color: lightgrey;
  list-style-type: disc;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
  padding-inline-start: 0;
}

.active {
  color: #000000;
}

.progressbarItem {
  list-style-type: none;
  font-size: 12px;
  float: left;
  position: relative;
}

.progressbarIcon {
  left: calc(50% - 20px);
  top: 18px;
  position: absolute;
  width: 40px;
  height: 40px;
  z-index: 2;
  color: #ffffff;
}

.progressbarIcon > :global(.MuiSvgIcon-root) {
  width: unset;
  height: unset;
}

.clickable {
  cursor: pointer;
}

.progressbarText {
  left: calc(50% - 38px);
  position: relative;
  width: 76px;
  text-align: center;
}

.progressbarItem:before {
  width: 76px;
  height: 76px;
  line-height: 45px;
  display: block;
  font-size: 18px;
  color: #ffffff;
  background: lightgray;
  border-radius: 50%;
  margin: 0 auto 10px auto;
  padding: 2px;
  content: "";
}

.progressbarItem:after {
  content: "";
  width: 100%;
  height: 2px;
  background: lightgray;
  position: absolute;
  left: 0;
  top: 38px;
}

.progressbarItem:first-child:after {
  width: 50%;
  left: 50%;
}

.progressbarItem:last-child:after {
  width: 50%;
  left: 0;
}

.progressbarItem.active:before,
.progressbarItem.active:after {
  background: #ff5722;
}
